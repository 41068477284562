import { Grid } from "@mui/material"
import { CustomTabPanel } from "../../../../../components/componentsAnaliseClientes/CustomTabPanel"
import { TabSoldDataChartItem } from "./item";

export const TabSoldDataChart = ({ data, value }) => {

    return <CustomTabPanel value={value} index={1} style={{ padding: '16px' }}>
        <Grid container spacing={2} rowGap={4}>
            <TabSoldDataChartItem title="Ano Atual" data={data?.currentYear} />
            <TabSoldDataChartItem title="Ano Anterior" data={data?.pastYear} />
            <TabSoldDataChartItem title="Período anterior" data={data?.pastPeriod} />
        </Grid>
    </CustomTabPanel>
}