import { Box, Grid, Paper, Typography } from "@mui/material"
import { formatCurrency } from "../../../../../util/number"

export const TabCashbackCardsItem = ({ title, data }) => {
    return <Grid item xs={12} md={4} container gap={2}>

        <Typography
            variant="h6"
            fontWeight={"normal"}
        >
            {title}
        </Typography>
        <Box
            display={"flex"}
            flexDirection={"column"}
            p={1}
            justifyContent={"space-between"}
            component={Paper}
            elevation={1}
            variant="outlined"
            width='100%'
        >
            <Typography
                variant="subtitle1"
                fontWeight={"normal"}
            >
                <strong>Disponível</strong>
            </Typography>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1,
                    marginTop: 2
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Valor
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatCurrency(data?.currentYear?.vlrTotalDisponivel || 0)}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Quantidade
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {data?.currentYear?.totalPedidosCashbackDisponivel || 0}
                </Typography>
            </Box>
        </Box>
        <Box
            display={"flex"}
            flexDirection={"column"}
            p={1}
            justifyContent={"space-between"}
            component={Paper}
            elevation={1}
            variant="outlined"
            width='100%'
        >

            <Typography
                variant="subtitle1"
                fontWeight={"normal"}
            >
                <strong>Utilizado</strong>
            </Typography>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1,
                    marginTop: 2
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Valor
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatCurrency(data?.currentYear?.vlrTotalUtilizado || 0)}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Quantidade
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {data?.currentYear?.totalPedidosCashbackUtilizado || 0}
                </Typography>
            </Box>
        </Box>
        <Box
            display={"flex"}
            flexDirection={"column"}
            p={1}
            justifyContent={"space-between"}
            component={Paper}
            elevation={1}
            variant="outlined"
            width='100%'
        >

            <Typography
                variant="subtitle1"
                fontWeight={"normal"}
            >
                <strong>Vencido</strong>
            </Typography>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1,
                    marginTop: 2
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Valor
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatCurrency(data?.currentYear?.vlrTotalVencido || 0)}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Quantidade
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {data?.currentYear?.totalPedidosCashbackVencido || 0}
                </Typography>
            </Box>
        </Box>
    </Grid>
}