import { Grid } from "@mui/material"
import { CustomTabPanel } from "../../../../../components/componentsAnaliseClientes/CustomTabPanel"
import { TabCashbackItem } from "./item";

export const TabCashbackChart = ({ data, value }) => {

    return <CustomTabPanel value={value} index={1} style={{ padding: '16px' }}>
        <Grid container spacing={2} rowGap={4}>
            <TabCashbackItem title="Baby+" data={data?.babyMais} />
            {!!data?.babyMaisPrime && <TabCashbackItem title="Baby+Prime" data={data?.babyMaisPrime} />}
        </Grid>
    </CustomTabPanel>
}