import { Box, Grid, Paper, Typography } from "@mui/material"
import { formatCurrency, formatDecimal } from "../../../../../util/number"

export const TabNumberOfSalesCardItem = ({ title, data }) => {

    return <Grid item xs={12} md={4} container gap={2}>

        <Typography
            variant="h6"
            fontWeight={"normal"}
        >
            {title}
        </Typography>
        <Box
            display={"flex"}
            flexDirection={"column"}
            p={1}
            justifyContent={"space-between"}
            component={Paper}
            elevation={1}
            variant="outlined"
            width='100%'
        >
            <Typography
                variant="subtitle1"
                fontWeight={"normal"}
            >
                <strong>1ª compra</strong>
            </Typography>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1,
                    marginTop: 2
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Valor vendido
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatCurrency(data?.primeiraCompra?.vlrTotal || 0)}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Quantidade
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {data?.primeiraCompra?.totalClientes || 0}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Ticket Médio
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatCurrency(data?.primeiraCompra?.ticketMedio || 0)}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Lucratividade
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatDecimal(data?.primeiraCompra?.lucratividade || 0)}%
                </Typography>
            </Box>

            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Representatividade
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatDecimal(data?.primeiraCompra?.representatividade || 0)}%
                </Typography>
            </Box>
        </Box>
        <Box
            display={"flex"}
            flexDirection={"column"}
            p={1}
            justifyContent={"space-between"}
            component={Paper}
            elevation={1}
            variant="outlined"
            width='100%'
        >

            <Typography
                variant="subtitle1"
                fontWeight={"normal"}
            >
                <strong>2ª compra</strong>
            </Typography>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1,
                    marginTop: 2
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Valor vendido
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatCurrency(data?.segundaCompra?.vlrTotal || 0)}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Quantidade
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {data?.segundaCompra?.totalClientes || 0}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Ticket Médio
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatCurrency(data?.segundaCompra?.ticketMedio || 0)}
                </Typography>
            </Box>

            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Lucratividade
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatDecimal(data?.segundaCompra?.lucratividade || 0)}%
                </Typography>
            </Box>

            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Representatividade
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatDecimal(data?.segundaCompra?.representatividade || 0)}%
                </Typography>
            </Box>
        </Box>
        <Box
            display={"flex"}
            flexDirection={"column"}
            p={1}
            justifyContent={"space-between"}
            component={Paper}
            elevation={1}
            variant="outlined"
            width='100%'
        >

            <Typography
                variant="subtitle1"
                fontWeight={"normal"}
            >
                <strong>3ª compra ou mais</strong>
            </Typography>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1,
                    marginTop: 2
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Valor vendido
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatCurrency(data?.terceiraCompra?.vlrTotal || 0)}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Quantidade
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {data?.terceiraCompra?.totalPedidos || 0}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Ticket Médio
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatCurrency(data?.terceiraCompra?.ticketMedio || 0)}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Lucratividade
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >
                    {formatDecimal(data?.terceiraCompra?.lucratividade || 0)}%
                </Typography>
            </Box>

            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{
                    borderBottomStyle: 'dashed',
                    borderBottomWidth: 1
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"normal"}

                >
                    Representatividade
                </Typography>
                <Typography component={"h5"} variant="subtitle1"
                >

                    {formatDecimal(data?.terceiraCompra?.representatividade || 0)}%
                </Typography>
            </Box>
        </Box>
    </Grid>
}