import { Grid } from "@mui/material"
import { CustomTabPanel } from "../../../../../components/componentsAnaliseClientes/CustomTabPanel"
import { TabSoldDataCardItem } from "./item";

export const TabSoldDataCards = ({ data, value }) => {

    return <CustomTabPanel value={value} index={0} style={{ padding: '16px' }}>
        <Grid container spacing={2}>
            <TabSoldDataCardItem title="Ano Atual" data={data?.currentYear} />
            <TabSoldDataCardItem title="Ano Anterior" data={data?.pastYear} />
            <TabSoldDataCardItem title="Período anterior" data={data?.pastPeriod} />
        </Grid>
    </CustomTabPanel>
}