import { Grid } from "@mui/material"
import { CustomTabPanel } from "../../../../../components/componentsAnaliseClientes/CustomTabPanel"
import { TabCashbackCardsItem } from "./item"

export const TabCashbackCards = ({ data, value }) => {
    return <CustomTabPanel value={value} index={0} style={{ padding: '16px' }}>
        <Grid container spacing={2}>
            <TabCashbackCardsItem title="Baby+" data={data?.babyMais} />
            {!!data?.babyMaisPrime && <TabCashbackCardsItem title="Baby+Prime" data={data?.babyMaisPrime} />}
        </Grid>
    </CustomTabPanel>
}