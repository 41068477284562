import { api } from "../services/api.v2";
import { AT_STORAGE, RT_STORAGE } from "./storageConfig";

export const storageRefreshTokenGet = () => {
  return localStorage.getItem(RT_STORAGE);
};

export const storageRefreshTokenSave = (refreshToken) => {
  return localStorage.setItem(refreshToken);
};

export const storageAccessTokenSave = (accessToken) => {
  api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  if (!accessToken) {
    return localStorage.removeItem(AT_STORAGE);
  }
  localStorage.setItem(AT_STORAGE, accessToken);
};

export const storageAccessTokenGet = () => {
  const accessToken = localStorage.getItem(AT_STORAGE);
  if (localStorage.getItem(AT_STORAGE)) {
    api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  }
  return accessToken;
};
