import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import { api } from "../services/api.v2";
import { isEmptyObject } from "../util/isEmptyObject";
import { Container } from "@mui/material";
import { LoadingTable } from "../components/Loading/LoadingTable";
import { arrayBases } from "../util/loja";

const UserDataContext = createContext({});

export const UserDataProvider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const [basesUserAccess, setBaseUserAccess] = useState([]);
  const [error, setError] = useState(null); // Novo estado para rastrear erros
  const [isLoading, setIsLoading] = useState(true);
  const { isLoadingUserData, accessToken } = useAuth();

  const getProfileUser = async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/profile");

      if (response.status === 200) {
        const bases = arrayBases(response.data.baseAcesso);
        setBaseUserAccess(bases);
        setUserData(response.data);
      }
    } catch (error) {
      console.error("Erro ao obter os dados do usuário:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getProfileUser();
    }
  }, [isLoadingUserData, accessToken]);

  if (isEmptyObject(userData)) {
    return (
      <Container
        sx={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <LoadingTable isLoading />
      </Container>
    );
  }

  return (
    <UserDataContext.Provider value={{ userData, isLoading, basesUserAccess }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => {
  const context = useContext(UserDataContext);
  return context;
};
