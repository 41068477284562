import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./styles.css";

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? " whitesmoke" : "white",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: " #eb0058",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#FFF",
    fontWeight: "bold",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#ff0d6c",
      color: "white",
    },
  }),
};

export const GenericSelect = ({
  options = [],
  isLoading = false,
  onChange,
  value = null,
  label = "",
  placeholder = "Selecione uma opção",
  name
}) => {
  const animatedComponents = makeAnimated();

  return (
    <div>
      <strong className="font-weight-bold">{label}</strong>
      <Select
        components={animatedComponents}
        name={name}
        value={value}
        placeholder={placeholder}
        isDisabled={isLoading}
        options={options}
        onChange={(e) => onChange(e)}
        classNames={{
          control: (state) => state.isFocused && "borderIsFocus",
        }}
        styles={colourStyles}
      />
    </div>
  );
};
