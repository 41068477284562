import { converterLoja } from "../../util/loja";

export const columnsTable = [
  {
    name: <strong style={{ fontSize: "1rem" }}>Loja</strong>,
    selector: (row) => row.codLoja,
    sortable: true,
    cell: (row) => converterLoja(row.codLoja),
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Quantidade de cadastros</strong>,
    selector: (row) => row.count,
  },
];

export const columnsTableRegisters = [
  {
    name: <strong style={{ fontSize: "1rem" }}>Nome</strong>,
    selector: (row) => row.nome,
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Telefone</strong>,
    selector: (row) => row.fone,
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Loja</strong>,
    selector: (row) => row.codLoja,
    sortable: true,
    cell: (row) => converterLoja(row.codLoja),
  },
];
